@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}

a {
  color: #84309c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.css-r6z5ec {
  overflow: hidden;
  transition: height 500ms ease;
}

.chakra-menu__menu-list {
  transition: height 500ms ease;
}

/* chakra ui menu item */
.css-593qcw {
  width: var(--menu-item-width) !important;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit {}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

#carousel.swiper {
  width: 100%;
}

#carousel.swiper.sm\:rounded-2xl {
  border-radius: 0.5rem;
}

#carousel .swiper-slide {
  max-width: 100%;
}

#carousel .swiper-slide.sm\:max-w-none {
  max-width: none;
}

#carousel:hover .swiper-button-prev,
#carousel:hover .swiper-button-next,
.collections-slide:hover .swiper-button-prev,
.collections-slide:hover .swiper-button-next {
  opacity: 1;
}

#carousel .swiper-button-prev,
#carousel .swiper-button-next {
  display: none;
  height: 32px;
  width: 32px;
  /* overflow: hidden;
  border-radius: 50%; */
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* padding: 1rem;
  opacity: 0; */
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collections-slide .swiper-button-prev,
.collections-slide .swiper-button-next {
  display: none;
}

@media screen and (min-width: 992px) {

  #carousel .swiper-button-prev,
  #carousel .swiper-button-next {
    display: block;
  }

  #carousel .swiper-button-next::after,
  .collections-slide .swiper-button-next::after {
    display: none;

  }

}


#carousel .swiper-button-next::after,
.collections-slide .swiper-button-next::after {
  content: '';
  background-image: url('/public/images/example/icon-right.svg');
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

}

#carousel .swiper-button-prev::after,
.collections-slide .swiper-button-prev::after {
  content: '';
  background-image: url('/public/images/example/icon-left.svg');
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.collections-slide .swiper-button-prev,
.collections-slide .swiper-button-next {
  border: 1px solid;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.collections-slide .swiper-button-disabled {
  display: none !important;
}

#main-tabs {
  mask: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 5%, rgb(0, 0, 0) 95%, rgba(0, 0, 0, 0) 100%);
}

@media (min-width: 640px) {
  #main-tabs {
    mask: none;
  }
}


#main-tabs .swiper {
  padding: 0.5rem;
}

#main-tabs .swiper-slide {
  width: fit-content;
}

#main-tabs .swiper-button-next,
#main-tabs .swiper-button-prev {
  display: none;
}

.collections-slide {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.collections-slide .swiper-slide {
  width: 75%;
}

.react-datepicker-wrapper {
  width: 100%;
}